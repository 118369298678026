<div id="notifications" class="notifications">
    <div class="notifications-head">
        <h2>{{ 'TopBar.Notifications.Title' | translate: { count: count } }}</h2>

        @if (count > 0) {
            <div
                ngbRadioGroup
                (change)="onFilterChange()"
                name="notificationFilter"
                [ngModel]="showUnreadOnly"
            >
                <label ngbButtonLabel class="btn-filter">
                    <input ngbButton type="radio" [value]="false" />{{ 'CommonTexts.All' | translate}}
                </label>
                <label ngbButtonLabel class="btn-filter">
                    <input ngbButton type="radio" [value]="true" />{{ 'CommonTexts.Unread' | translate }}
                </label>
            </div>
        }
    </div>
    <div
        class="notifications-list"
        [class.block-loader]="loader.isLoading$ | async"
    >
        <loader-spinner [model]="loader"/>

        @for (notification of notifications?.items; track notification) {
            <notification-item [notification]="notification" (click)="select(notification)"/>
        }
    </div>
</div>
