import { Injector, Type } from '@angular/core';
import { IFilter, ISearchItem, ISearchResults, ISearchResultsByCategory, ISearchViewMore, SearchResultCategory } from '@aston/foundation';
import { Action, ActionCreator } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, startWith } from 'rxjs';

interface MoreLikeSpecs { named(term: string): IFilter; }
interface SearchByCategorySpecs<Category> { searchByCategory: (category: Category, term: string) => Observable<ISearchResults> }
export interface ExpectedActions {
	AfterInitRequest: ActionCreator;
	ResetSearchFilters: ActionCreator;
	AddSearchFilters: ActionCreator;
}
interface AppStoreActions {
	NavigateByUrl: ActionCreator;
}
interface ISearchProvider<Category extends string> {
	search: (injector: Injector, term: string) => { [K in Category]: Observable<ISearchResults>; };
	handle: (injector: Injector, item: ISearchItem | ISearchViewMore) => Action[];
	withMoreInitActions: (fn: (injector: Injector, item: ISearchViewMore) => Action[]) => ISearchProvider<Category>;
}

type Counter = { [key: string]: number };

export const orderSearchResults = (results: ISearchResultsByCategory, moreResultsLikeFn: (category: SearchResultCategory) => object): (ISearchItem | ISearchViewMore)[] => {
	const order = Object.keys(results).reduce((acc, key, index) => {
		acc[key] = index + 1;
		return acc;
	}, {} as Counter);

	return Object.entries(results)
		.sort((a, b) => order[a[0]] - order[b[0]])
		.reduce((acc, [category, results]) => acc
			.concat(results.items)
			.concat(results.items.length ? [moreResultsLikeFn(category as unknown as SearchResultCategory) as ISearchViewMore] : []),
		[] as (ISearchItem|ISearchViewMore)[])
}

export function moreResultsLike(translateService: TranslateService, like: string, category: SearchResultCategory): ISearchViewMore {
	return {
		label: translateService.instant('TopBar.Search.MoreOf.' + category),
		category,
		like,
	}
}

export function emptySearchResults(category: SearchResultCategory) {
	return ({ items: [], totalItemCount: 0, category });
}

export function searchForCategoryProvider<Category extends string, Actions extends ExpectedActions>(
	category: Category,
	toMoreUrl: string,
	toItemUrl: (item: ISearchItem) => string,
	appActions: AppStoreActions,
	expectedActionsProvider: Actions | ((injector: Injector) => Actions),
	SearchToken: Type<SearchByCategorySpecs<Category>>,
	SpecToken: Type<MoreLikeSpecs>
): ISearchProvider<Category> {
	const expectedActions = (injector: Injector) => typeof(expectedActionsProvider) === 'function'
		? expectedActionsProvider(injector)
		: expectedActionsProvider;

	const searchOperator = (injector: Injector, term: string) => ({
		[category]: injector.get(SearchToken).searchByCategory(category, term).pipe(startWith(emptySearchResults(category)))
	}) as {[K in Category]: Observable<ISearchResults>}

	const afterInitActions = (injector: Injector, item: ISearchViewMore) => [
		expectedActions(injector).ResetSearchFilters(),
		expectedActions(injector).AddSearchFilters({ filters: [
			{ ...injector.get(SpecToken).named(String(item.like)), isTransient: true },
		] }),
	];

	let moreInitActions = (_injector: Injector, _item: ISearchViewMore) => [] as Action[];

	const viewMoreOperator = (injector: Injector, item: ISearchViewMore) => ([
		expectedActions(injector).AfterInitRequest({
			dispatch: afterInitActions(injector, item).concat(moreInitActions(injector, item))
		}),
	] as Action[])
		.filter(a => Object.values({ ...appActions, ...expectedActions(injector) as object }).find(da => da?.type === a?.type)
			|| console.error('[search] %o is not an expected action', a))
		.concat(appActions.NavigateByUrl({ to: toMoreUrl }) as Action)

	const itemNavigator = (item: ISearchItem) => appActions.NavigateByUrl({ to: toItemUrl(item) }) as unknown as Action;

	const selectOperator = (injector: Injector, item: ISearchItem | ISearchViewMore): Action[] => {
		if (item.category !== category) return [];

		if (item.like) {
			return viewMoreOperator(injector, item);

		} else if ('value' in item) {
			return [itemNavigator(item)];
		}

		return []
	};

	return {
		search: searchOperator,
		handle: selectOperator,

		withMoreInitActions(fn: (injector: Injector, item: ISearchViewMore) => Action[]) {
			moreInitActions = fn;
			return this;
		}
	}
}


export function provideSearchActions(searchActions: object) {
	return {
		searchActions
	}
}
