<div class="notifications-head">
	<h2>
		{{ 'TopBar.Notifications.Title' | translate }}
		<br><small><a (click)="close()" routerLink="/notifications" translate="TopBar.Notifications.All"></a></small>
	</h2>
	<btn-group-switch (valueChange)="toggleMode($event)" [selected]="showUnreadOnly$ | async" [buttons]="[
		{label: 'CommonTexts.All', value: false },
		{label: 'CommonTexts.Unread', value: true }
	]"/>
</div>

<notifications-list
	[notifications]="list$ | async"
	[loader]="loader"
	(action)="open($event.value)"
/>
