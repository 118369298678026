import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { IFactorConfiguration, FactorConfigurationService } from '@aston/foundation';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { AppConstants } from 'apps/federation/src/app/app.constants';

@Injectable({
	providedIn: 'root'
})
export class FederationFactorConfigurationService extends FactorConfigurationService {

	constructor(
		private http: HttpClient,
		private store: Store
	) {
		super();
	}

	loadFactorConfig(): Observable<void> {
		return EMPTY;
	}

	getFactorConfig(): Observable<IFactorConfiguration> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IFactorConfiguration>(`${config.apiUrl}/api/Configuration`)
			.pipe(map(x => [x, config]))),
			map(([factorConfig, _]) => <IFactorConfiguration> factorConfig),
			catchError(_ => of(AppConstants.DEFAULT_FACTOR_CONFIGURATION))
		);
	}
}
