<div
    class="card-item notification-item"
    [class.notification-item-read]="notification.isRead"
>
    <header class="card-item-head notification-item-head">
        <div>
            <span class="name">{{ notification.relatedEntityName }}</span>
            <span class="badge badge-{{ notification | notificationStatus: 'badgeClass' }}">
                {{ 'TopBar.Notifications.Categories.' + notification.category | translate }}
            </span>
        </div>
        <div class="card-item-date">
            {{ notification.creationDate | dateFormat: 'WithHours' }}
        </div>
    </header>

    <div class="card-item-body notification-item-body">
        <div class="notification-item-content-head">
            @if (isSystemUser) {
                <user-avatar [isSystem]="true"/>
            } @else {
                <user-avatar
                    [user]="
                        notification.realizedByUser &&
                        !notification.realizedByUser.notdefined
                            ? notification.realizedByUser
                            : notification.user
                    "
                    [hasFullName]="true"
                />
            }
        </div>
    </div>
</div>
