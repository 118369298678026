[
  "app",
  "authentication",
  "debtors",
  "errors",
  "forms",
  "menu",
  "accounting",
  "dunning",
  "import",
  "home",
  "admin"
]