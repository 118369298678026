
import { Component, OnInit, viewChild } from '@angular/core';
import { BreadcrumbComponent, ClickOutsideDirective, DebugBarComponent, LanguageService, ScrolledByContainerDirective } from '@aston/foundation';
import { ActionType, AppLanguage } from '@aston/foundation';
import { AuthenticationService } from 'apps/federation/src/app/authentication-module/services';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { NotificationsStoreSelectors, NotificationsStoreActions } from 'apps/federation/src/app/root-store/notifications-store';
import { StoreLoader, UnsubscriberClass, IAction } from '@aston/foundation';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppStoreActions, AppStoreSelectors } from 'apps/federation/src/app/root-store/app-store';
import { environment } from 'apps/federation/src/environments/environment';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { UserPrefsStoreActions } from '@aston/user-prefs';
import { AsyncPipe } from '@angular/common';
import { ThemesModule } from '@aston/themes';

import { UserClearanceLevel } from '../../../authentication-module/enums';
import { NotificationCategory } from '../../enums';
import { INotificationItem, IUserAvatar } from '../../models';
import { NotificationsPanelComponent } from '../../components/notifications-panel/notifications-panel.component';
import { SidePanelComponent } from '../../components/side-panel/side-panel.component';
import { TopBarComponent } from '../../components/top-bar/top-bar.component';
import { MenuComponent } from '../../components/menu/menu.component';

@Component({
    selector: 'master-layout',
    templateUrl: './master-layout.component.html',
    standalone: true,
    imports: [
		AsyncPipe,
		BreadcrumbComponent,
		ClickOutsideDirective,
		DebugBarComponent,
		MenuComponent,
		NotificationsPanelComponent,
		RouterOutlet,
		ScrolledByContainerDirective,
		SidePanelComponent,
		ThemesModule,
		TopBarComponent,
	]
})
export class MasterLayoutComponent extends UnsubscriberClass implements OnInit {
	debugBar = viewChild(DebugBarComponent)
	menuCollapsed = false;

	currentLanguage$ = this.store.select(AppStoreSelectors.selectCurrentLanguage).pipe(
		map(language => ({ language: language.language as AppLanguage})));
	currentUserAvatar$: Observable<IUserAvatar> = combineLatest([
		this.store.select(AppStoreSelectors.selectCurrentUser),
		this.store.select(AppStoreSelectors.selectCurrentUserAvatar),
	]).pipe(
		filter(([user]) => !!user),
		map(([_, avatar]) => avatar),
	);

	tenant$ = this.store.select(AppStoreSelectors.selectTenant);
	pageOverTitle$ = this.tenant$.pipe(map(tenant => tenant ? tenant.name : ''));
	pageOverLogo$ = this.store.select(AppStoreSelectors.selectTenantLogo);

	isSupport$ = this.store.select(AppStoreSelectors.selectCurrentUserIsSupport);
	hasImportHistoryClearanceLevel: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.HistoryImport));
	hasManualImportClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.ManualImport));
	hasLetteringClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.Lettering));
	hasPaymentOrderClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.PaymentOrder));
	hasAdministrationToolsClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.AdministrationTools));
	hasSuperAdministrationClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.SuperAdministration));
	hasReadOnlyAccessToAllSuperDebtorsClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.ReadOnlyAccessToAllSuperDebtors));
	hasDunningClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.Dunning));

	themesIsOpened = false;
	notificationsIsOpened = false;

	notificationsList$: Observable<INotificationItem[]> = this.store.select(NotificationsStoreSelectors.selectNotifications);
	notificationsAllCount$: Observable<number> = this.store.select(NotificationsStoreSelectors.selectAllCount);
	notificationsHasUnread$: Observable<boolean> = this.store.select(NotificationsStoreSelectors.selectHasUnread);
	notificationsShowUnreadOnly$: Observable<boolean> = this.store.select(NotificationsStoreSelectors.selectShowUnreadOnly);

	notificationsLoader = new StoreLoader(
		this.store.select(NotificationsStoreSelectors.selectNotificationsIsLoading),
		this.store.select(NotificationsStoreSelectors.selectNotificationsError)
	);

	// Use it to stop notifications refresh and avoid redux devtools flooding
	enableNotificationRefresh: boolean = environment.name !== 'Local';

	isHome = false;

	constructor(private router: Router,
		private languageService: LanguageService,
		private authenticationService: AuthenticationService,
		private store: Store,
		) {

		super();
		router.events.subscribe((e) =>  {
			if (e instanceof NavigationEnd) {
				this.isHome = e.url === "/home" ? true : false
			}
		});
	}

	ngOnInit() {
		this.store.select(NotificationsStoreSelectors.selectIsOpened)
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(isOpened => this.notificationsIsOpened = isOpened);
	}

	toggleNotificationPanel() {
		this.enableNotificationRefresh = true;
		this.store.dispatch(NotificationsStoreActions.ToggleList());

		// let's ask for a notification list right now.
		this.store.dispatch(NotificationsStoreActions.LoadNotificationsRequest());
	}

	toggleThemesPanel() {
		setTimeout(_ => {
			this.notificationsIsOpened = false
			this.themesIsOpened = !this.themesIsOpened;
		}, 100)
	}

	handleAction(action: IAction, origin: 'menu' | 'notifications' | 'themes' = action.value) {
		switch (action.type) {
			case ActionType.OPEN:
				this.closePanels()
				break
			case ActionType.TOGGLE:
				if (origin === 'menu') {
					this.menuCollapsed = !this.menuCollapsed;
				} else if (origin === 'notifications') {
					this.toggleNotificationPanel();
				} else if (origin === 'themes') {
					this.toggleThemesPanel();
				}
				break;
		}
	}

	selectLanguage(language: AppLanguage) {
		this.store.dispatch(AppStoreActions.ChangeLanguage({ language }));
		this.store.dispatch(AppStoreActions.SetUserLanguageRequest({ language }));
	}

	logout() {

		const logoutAction = AppStoreActions.Logout()
		this.store.dispatch(AppStoreActions.OpenConfirmationModal({textsKey: `Modal.ConfirmationDeconnexion.`}, logoutAction));
	}

	closePanels() {
		this.themesIsOpened = false
		if (this.notificationsIsOpened) {
			this.store.dispatch(NotificationsStoreActions.ClosePanel());
		}
	}

	filterNotifications(showUnread: boolean) {
		if (showUnread) {
			this.store.dispatch(NotificationsStoreActions.ShowUnreadOnly());
		} else {
			this.store.dispatch(NotificationsStoreActions.ShowAll());
		}
	}

	selectNotification(notification: INotificationItem) {
		let redirectionRoute = '';
		let redirectionExtras;

		switch (notification.category) {
			case NotificationCategory.None:
				redirectionRoute = '';
				break;
		}

		this.store.dispatch(NotificationsStoreActions.SelectRequest(notification.id, redirectionRoute, redirectionExtras));
	}

	useTheme(theme) {
		this.store.dispatch(UserPrefsStoreActions.UpdatePreferredTheme({theme}))
	}
}

