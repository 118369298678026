<page-header
    class="tenant-title"
    [pageTitle]="tenantName | uppercase"
    [ngbTooltip]="'Formats.LastUpdateDate' | translate: { date: (tenantLastUpdateDate | dateFormat: 'WithHours2') }"
>
    @if (tenantLogo) {
        <div
            page-header-area="icon"
            class="tenant-logo"
            [style.background-image]="'url(' + tenantLogo + ')'"
        ></div>
    }
</page-header>
<div class="user-actions">
    <multi-search-input
        [groups]="searchGroups()"
        [results]="searchResultsWithMore()"
        (search)="search($event)"
        (pick)="pickSearchResult($event)"
    />
    <button
        class="btn-notifications btn-badged d-none"
        (click)="toggleNotificationsPanel()"
    >
        <i
            class="fal fa-bell"
            ngbTooltip="{{ 'TopBar.Tooltip.Notifications' | translate }}"
            placement="left"
            container="body"
        ></i>
        @if (unreadNotificationsCount$ | async; as unread) {
            <span class="badge badge-pill badge-secondary">{{ unread <= 9 ? unread : '9+' }}</span>
        }
    </button>
    <button class="btn-help" (click)="openHelp()">
        <i
            class="fal fa-question-circle"
            ngbTooltip="{{ 'TopBar.Tooltip.Help' | translate }}"
            placement="left"
            container="body"
        ></i>
    </button>
    @if (hasTranslations) {
        <div class="user-languages" ngbDropdown placement="bottom-right">
            <button class="btn btn-primary" ngbDropdownToggle data-cy="DropdownToggle">
                {{ currentLanguage | language: true }}
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <button (click)="setLanguage(fr)" class="dropdown-item">FR</button>
                <button (click)="setLanguage(en)" class="dropdown-item">EN</button>
            </div>
        </div>
    }
    @if (!!user) {
        <div class="user-account" ngbDropdown placement="bottom-right">
            <user-avatar [user]="user" ngbDropdownToggle data-cy="DropdownToggle" class="no-carret"/>

            <div ngbDropdownMenu>
                @if (operator$ | async; as operator) {
                    @if (!operator.isSupport) {
                        <button
                            class="dropdown-item"
                            (click)="editMyInformations()"
                        >
                            <i class="mr-2 fa fa-user"></i>
                            {{ 'TopBar.Account.MyAccount' | translate }}
                        </button>
                    }
                }
                <button class="dropdown-item" (click)="toggleThemesPanel()">
                    <i class="mr-2 fal fa-palette"></i>
                    {{ 'App.Themes.Title' | translate }}
                </button>
                <button class="dropdown-item" (click)="leavePlatform()">
                    <i class="mr-2 fa fa-door-open"></i>
                    {{ 'TopBar.Account.Leave' | translate }}
                </button>
                @if ((config$ | async).partnershipsUrl; as partnershipsUrl) {
                    <a [href]="partnershipsUrl" class="dropdown-item">
                        <i class="mr-2 fa fa-handshake"></i>
                        {{ 'TopBar.Account.Partnerships' | translate }}</a>
                }
                <button class="dropdown-item" (click)="userLogout()">
                    <i class="mr-2 fa fa-sign-out-alt"></i>
                    {{ 'TopBar.Account.LogOut' | translate }}
                </button>
            </div>
        </div>
    }
</div>
