import { Component, Output, EventEmitter, Input } from '@angular/core';
import { IAction } from '@aston/foundation';

@Component({
    selector: 'side-panel',
    templateUrl: './side-panel.component.html',
    standalone: true
})
export class SidePanelComponent {
	@Input() open: boolean;
	@Output() action: EventEmitter<IAction> = new EventEmitter();
}
