
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, zip } from 'rxjs';
import { Router } from '@angular/router';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStoreActions, AppStoreSelectors } from 'apps/federation/src/app/root-store/app-store';
import { AppConstants } from 'apps/federation/src/app/app.constants';
import { UserService } from 'apps/federation/src/app/user-module/services';
import { AUTH_ERRORS } from '@aston/foundation';

import { AuthenticationService } from '../services';

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptor implements HttpInterceptor {
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	block401ManagementForNow: boolean;
	block403ManagementForNow: boolean;

	constructor(
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private router: Router,
		private store: Store) {
	}

	// http://ericsmasal.com/2018/07/02/angular-6-with-jwt-and-refresh-tokens-and-a-little-rxjs-6/
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// do not send authentication info for assets and connection request
		if (req.url.includes('/assets')) {
			return next.handle(req);
		}

		if (req.url.includes('connect/token')) {
			return next.handle(this.addTenantToRequest(req, this.authenticationService.getTenantId()));
		}

		return this.authenticationService.getAccessToken().pipe(
			switchMap((token: string) => next.handle(this.addTokenAndTenantToRequest(
				req,
				token,
				this.authenticationService.getTenantId())
			)),
			tap(_ => {
				// Update activity date on each successful api request
				if (!AppConstants.REFRESH_ACTIVITY_EXCLUDED_APIS.some(v => req.url.includes(v))) {
					this.store.dispatch(AppStoreActions.UpdateUserSessionRequest({}));
				}
			}),
			catchError(err => {
				switch ((<HttpErrorResponse> err).status) {
					case 400:
						return this.handle400Error(err);
					case 0:
					case 401:
						if (err.error?.SpecificError === AUTH_ERRORS.LoggedInUserDoesNotExists ||
							err.originalError?.error?.SpecificError === AUTH_ERRORS.LoggedInUserDoesNotExists) {
							this.store.dispatch(AppStoreActions.CriticalFailure({error: err, goToConsole: true}));
							return throwError(err);
						}
						if (err.error?.SpecificError === AUTH_ERRORS.UserSignedOut ||
							err.originalError?.error?.SpecificError === AUTH_ERRORS.UserSignedOut) {
							this.authenticationService.revokeTokens();
							return throwError(err);
						}
						// probably a CORS error due to token expiration
						return this.handle401Error(req, next, err);
					case 403:
						// maybe the user clearances changed
						return this.handle403Error(req, next, err);
					default:
						return throwError(err);
				}
			}
		));
	}

	addTenantToRequest(req: HttpRequest<any>, tenant: string): HttpRequest<any> {
		return req.clone({
			setHeaders: {
				'X-Aston-FederationId': tenant
			}
		});
	}

	addTokenAndTenantToRequest(req: HttpRequest<any>, token: string, tenant: string): HttpRequest<any> {
		return req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
				'X-Aston-FederationId': tenant
			}
		});
	}

	getAuthenticationTriesSettingsKey(): string {
		const tenantId = this.authenticationService.getTenantId();
		return AppConstants.LOCALSTORAGE_KEYS.AUTHENTICATION_TRIES + '_' + tenantId;
	}

	clearAuthenticationTries(): void {
		const authenticationTriesSettingsKey = this.getAuthenticationTriesSettingsKey();
		sessionStorage.setItem(authenticationTriesSettingsKey, '0');
	}

	saveAuthenticationTries(): void {

		const limit = Date.now() - AppConstants.AUTHENTICATION_MAX_TRY_DELAY_SECONDS * 1000;
		const authenticationTriesSettingsKey = this.getAuthenticationTriesSettingsKey();

		const authenticationTries = this.getAuthenticationTries();
		authenticationTries.push(Date.now());

		// we only keep the one in delay
		const reducer = (previousValue, currentValue) => previousValue + ((!isNaN(+currentValue) && +currentValue > limit) ? ';' + currentValue : '');
		const authenticationTriesInDelay = authenticationTries.map(val => '' + val).reduce(reducer, '0');

		sessionStorage.setItem(authenticationTriesSettingsKey, authenticationTriesInDelay);
	}

	getAuthenticationTries(): number[] {
		const authenticationTries = sessionStorage.getItem(this.getAuthenticationTriesSettingsKey())
		if (!authenticationTries) {
			return [0];
		}
		return authenticationTries.split(';').map(str => +str).filter(v => !isNaN(v));
	}

	handle400Error(error) {
		if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
			// If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
			return this.logoutUser();
		}

		return throwError(error);
	}

	handle401Error(req: HttpRequest<any>, next: HttpHandler, err: any): Observable<never> {

		if (this.block401ManagementForNow) {
			return throwError(err);
		}
		this.block401ManagementForNow = true;
		const limit = Date.now() - AppConstants.AUTHENTICATION_MAX_TRY_DELAY_SECONDS * 1000;

		const authenticationTries = this.getAuthenticationTries();
		const authenticationTriesInDelay = authenticationTries
		.reduce((previousValue, currentValue) => previousValue + (!isNaN(currentValue) && currentValue > limit ? 1 : 0), 0);

		// we only store the "retry" in local storage so we use 'AppConstants.AUTHENTICATION_MAX_TRY_IN_DELAY - 1'
		// instead of plain AppConstants.AUTHENTICATION_MAX_TRY_IN_DELAY.
		const maxTryNumber = AppConstants.AUTHENTICATION_MAX_TRIES_IN_DELAY - 1;
		if (authenticationTriesInDelay > maxTryNumber) {
			this.store.dispatch(AppStoreActions.CriticalFailure({error: err, goToConsole: false}));
			this.clearAuthenticationTries();
			return throwError(err);
		}


		this.saveAuthenticationTries();

		this.authenticationService.revokeTokens();

		// ask the user to log in again
		this.authenticationService.login();
		return throwError(err);
	}

	handle403Error(req: HttpRequest<any>, next: HttpHandler, err: any): Observable<never> {
		if (this.block403ManagementForNow) {
			return throwError(err);
		}
		this.block403ManagementForNow = true;

		return zip(
			this.store.select(AppStoreSelectors.selectCurrentUser),
			this.userService.refreshUserInfo()
		).pipe(
			map(([currentUserInfos, newUserInfos]) => {
				this.block403ManagementForNow = false;

				const clearancesChanged = JSON.stringify(currentUserInfos.clearanceLevels) !== JSON.stringify(newUserInfos.clearanceLevels);
				if (! clearancesChanged) {
					throw err;
				}

				// navigate to home, this page may not be available anymore
				this.store.dispatch(AppStoreActions.Navigate({ to: [RoutesDefinitions.getBasePath()] }));

				// dispatch a new login, redraw menu
				this.store.dispatch(AppStoreActions.Login({userData: newUserInfos}));

				throw err;
			}),
			catchError(_ => throwError(err)) // throw the original error
		)
	}

	logoutUser() {
		this.authenticationService.logout();
		this.router.navigate([RoutesDefinitions.getLoginFullPath()]);
		return throwError('');
	}
}
