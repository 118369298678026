import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, InjectionToken, LOCALE_ID, Provider } from '@angular/core';
import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';

import { AppErrorHandler } from '../classes';
import { getDatepickerConfig, getDaterangepickerConfig } from '../functions';
import { VersionInterceptor, CacheInterceptor, ErrorInterceptor } from '../interceptors';
import { BreadcrumbService, BrowserNavigationService, CriticalStateService, HotkeysService, LanguageService, PageLoaderService, PageService, TelemetryService } from '../services';

export const APP_HOME_URL = new InjectionToken<string>('APP_HOME_URL');

export function provideFoundationServices(): Provider[] {
    return [
        BreadcrumbService,
		BrowserNavigationService,
		CriticalStateService,
		HotkeysService,
		LanguageService,
		PageLoaderService,
		PageService,
		{
			provide: LOCALE_ID,
			useValue: 'fr-FR'
		},
		{
			provide: BsDatepickerConfig,
			useFactory: getDatepickerConfig
		},
		{
			provide: BsDaterangepickerConfig,
			useFactory: getDaterangepickerConfig
		},
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [TranslateService, LanguageService, CriticalStateService, TelemetryService] },
		{ provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true, deps: [TelemetryService] },
		{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },

    ]
}
