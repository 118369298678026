<div
    id="app"
    class="authenticated-layout"
    [class.with-debug-bar]="!debugBar()?.hidden()"
    [class.menu-collapsed]="menuCollapsed"
    [class.notif-opened]="notificationsIsOpened"
    [class.has-unread-notifications]="notificationsHasUnread$ | async"
>
    <nav
        id="menu"
        layout-menu
        (action)="handleAction($event, 'menu')"
        [isSupport]="isSupport$ | async"
        [hasImportHistoryClearanceLevel]="hasImportHistoryClearanceLevel | async"
        [hasManualImportClearanceLevel]="hasManualImportClearanceLevel$ | async"
        [hasAdministrationToolsClearanceLevel]="hasAdministrationToolsClearanceLevel$ | async"
        [hasSuperAdministrationClearanceLevel]="hasSuperAdministrationClearanceLevel$ | async"
        [hasReadOnlyAccessToAllSuperDebtorsClearanceLevel]="hasReadOnlyAccessToAllSuperDebtorsClearanceLevel$ | async"
    ></nav>

    <section id="content">
        <header
            id="top-bar"
            layout-top-bar
            [user]="currentUserAvatar$ | async"
            [currentLanguage]="(currentLanguage$ | async)?.language"
            [tenantName]="pageOverTitle$ | async"
            [tenantLogo]="pageOverLogo$ | async"
            [tenantConsoleId]="(tenant$ | async)?.tenantId"
            [tenantLastUpdateDate]="(tenant$ | async)?.lastUpdateDate"
            (selectLanguage)="selectLanguage($event)"
            (logout)="logout()"
            (action)="handleAction($event)"
        ></header>

        <main id="main" [class.overlay]="notificationsIsOpened" scrolledByContainer>
            <layout-breadcrumb/>
            <router-outlet/>
        </main>

        <side-panel
            (clickOutside)="closePanels()"
            exclude="#top-bar .btn-notifications, #top-bar"
            [open]="notificationsIsOpened || themesIsOpened"
        >
            @if (notificationsIsOpened) {
                <notifications-panel
                    id="notifications"
                    class="notifications"
                    (action)="handleAction($event, 'notifications')"
                />
            }
            @if (themesIsOpened) {
                <div class="theme-switcher-panel">
                    <h2 class="m-3" translate="App.Themes.Title"></h2>
                    <theme-switcher (selectTheme)="useTheme($event)"/>
                </div>
            }
        </side-panel>
    </section>
</div>

<debug-bar/>
