import { LogLevel, OpenIdConfiguration, StsConfigHttpLoader } from 'angular-auth-oidc-client';
import { stripSlashes } from '@aston/foundation';
import { take, delay, map } from 'rxjs';

import { AppConfiguration } from './app.configuration';
import { AuthenticationStorageService } from './authentication-module/services';

const AUTHENTICATION_START_DATE_KEY = 'AUTHENTICATION_START_DATE_KEY';

function setAuthenticationStartDate() {
	localStorage.setItem(AUTHENTICATION_START_DATE_KEY, new Date().toISOString());
}

function getLastAuthenticationStartDate() {
	const dateString = localStorage.getItem(AUTHENTICATION_START_DATE_KEY);
	return new Date(dateString);
}

function delayToWaitBeforeAuthentication(): number {
	// Gate signout an user before letting you sign-in.
	// if you launch 2 MiddleOffice at the same time, you get stuck in a loop
	// where both MOs signout each others
	// so we introduce a 7 seconds delay between 2 authentications.
	// this fix https://dev.azure.com/astonitf/LBP/_workitems/edit/18136
	const lastAuthenticationDate = getLastAuthenticationStartDate().getTime();
	const now = new Date().getTime();
	const deltaMilliseconds = now - lastAuthenticationDate;
	const delayBetween2Authentications = 7 * 1000;
	if (deltaMilliseconds >= delayBetween2Authentications) {
		return 0;
	}
	return delayBetween2Authentications - deltaMilliseconds;
}

// https://github.com/damienbod/angular-auth-oidc-client/blob/master/projects/sample-code-flow-http-config/src/app/app.module.ts
export function configureAuth(config: AppConfiguration) {
	const config$ = config.appConfigLoaded$.pipe(
		take(1),
		delay(delayToWaitBeforeAuthentication()),
		map<unknown, OpenIdConfiguration>(_ => {
			const authenticationServerUrl = stripSlashes(config.authenticationServerUrl);
			const baseUrl = `${stripSlashes(window.location.origin)}`;
			const appUrl = `${baseUrl}`;

			const logOffUrl = config.consoleUrl;
			setAuthenticationStartDate();
			return {
				authority: authenticationServerUrl,
				redirectUrl: `${appUrl}/authentication/callback`,
				clientId: config.clientId,
				responseType: 'code',
				scope: 'openid profile offline_access',
				postLogoutRedirectUri: logOffUrl,
				startCheckSession: false, // this seems to work only with a silentRenewUrl
				silentRenew: true,  // a timer is ticked each tokenRefreshInSeconds to check if the token is expired or not
				tokenRefreshInSeconds: 10, // value was increased because the refresh process took more than the default value and
				useRefreshToken: true, // requires offline_access scope
				ignoreNonceAfterRefresh: true, // specific to openIdDict
				renewTimeBeforeTokenExpiresInSeconds: 30,
				maxIdTokenIatOffsetAllowedInSeconds: 60, // offset in second allowed between openid server time and client time
				postLoginRoute: `${baseUrl}`,
				forbiddenRoute: `${baseUrl}`,
				unauthorizedRoute: `${baseUrl}`,
				logLevel: LogLevel.None, // LogLevel.Debug
				historyCleanupOff: true,
				autoUserInfo: false,
				storage: new AuthenticationStorageService()
			};
		}),
	);

	return new StsConfigHttpLoader(config$);
}
