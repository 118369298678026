
import { Component, OnInit } from '@angular/core';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';
import { AppConstants } from 'apps/federation/src/app/app.constants';
import { delay, takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { LoaderSpinnerComponent } from '@aston/foundation';

import { LoginPageComponent } from '../login-page/login-page.component';

@Component({
	selector: 'login-callback-page',
	templateUrl: '../login-page/login-page.component.html',
	standalone: true,
	imports: [FormsModule, LoaderSpinnerComponent]
})
export class LoginCallbackPageComponent extends LoginPageComponent implements OnInit {

	ngOnInit() {
		const redirectUrlVar = AppConstants.LOCALSTORAGE_KEYS.REDIRECT_URL;

		this.activatedRoute.queryParams.pipe(
			takeUntil(this.destroySubscriptions$)
		).subscribe(params => {
			this.authenticationService.getIsAuthorized().pipe(delay(0)).subscribe(isAuth => {
				if (isAuth) {
					const queryParams = { [redirectUrlVar]: params[redirectUrlVar] };
					this.router.navigate([RoutesDefinitions.getHomeFullPath()], { queryParams });

				} else {
					this.router.navigate([RoutesDefinitions.getLoginFullPath()]);
				}
			});
		})
	}
}
