import { emptyList, setError, setLoaded, setLoading, withCallState, ISearchItem, ISearchResultsByCategory, ISearchViewMore } from '@aston/foundation';
import { moreResultsLike, orderSearchResults } from '@aston/multi-search-input';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { EMPTY, Observable, catchError, combineLatest, map, of, startWith, switchMap, tap } from 'rxjs';
import * as Routes from 'apps/federation/src/app/routes-definitions';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { SearchResultCategory } from '../../enums';
import { AppConstants } from '../../../app.constants';
import { SuperCustomersListFilterSpecService, SuperCustomersListService } from '../../../customers-module/services';
import * as SuperCustomersStoreActions from '../../../root-store/super-customers-store/actions';
import * as AppStoreActions from '../../../root-store/app-store/actions';

const S = SearchResultCategory;
const emptySuperdebtorResults = { ...emptyList(), category: S.Customer, totalItemCount: 0 };

const searchCustomers = (service: SuperCustomersListService, term: string) => ({
	[S.Customer]: service.searchForTopBar(term, AppConstants.TOP_SEARCH_MAX_RESULTS * 2).pipe(
		startWith(emptySuperdebtorResults))
})

const moreCustomersResults = (specs: SuperCustomersListFilterSpecService, item: ISearchViewMore) => [
	SuperCustomersStoreActions.AfterInitRequest({
		dispatch: [
			SuperCustomersStoreActions.ResetSuperCustomersFilters(),
			SuperCustomersStoreActions.AddSuperCustomersFilters({ filters: [
				{ ...specs.named(item.like), isTransient: true },
			] }),
		]
	}),
	AppStoreActions.Navigate({ to: [Routes.getSuperCustomersListFullPath()] }),
]

export const initialState = {
	results: [] as unknown as ISearchResultsByCategory,
	sortedResultsWithMore: [] as (ISearchItem|ISearchViewMore)[],
	term: '',
}

export const TopBarSearchStore = signalStore(
	withState(initialState),
	withCallState(),
	withMethods(({ ...store }) => {
		const fatStore = inject(Store);
		const i18n = inject(TranslateService);
		const csService = inject(SuperCustomersListService)
		const csSpecs = inject(SuperCustomersListFilterSpecService)

		const searchEverything = (term: string) => combineLatest({
			...searchCustomers(csService, term),
		}) as Observable<ISearchResultsByCategory>

		return {

			search(term: string) {
				if ((!term || term.length < 3)) return;
				of(term).pipe(
					tap(_ => patchState(store, { term, ...setLoading() })),
					switchMap(searchEverything),
					map(results => patchState(store, { term, results,
						sortedResultsWithMore: orderSearchResults(
							results,
							category => moreResultsLike(i18n,  term, category)
						),
					})),
					tap(_ => patchState(store, setLoaded())),
					catchError(e => {
						patchState(store, setError(e))
						return EMPTY
					}),
				)
				.subscribe()
			},

			select(item: ISearchItem | ISearchViewMore) {
				if (!item) return;
				patchState(store, setLoaded());

				const actions = [];

				if (item.category === S.Customer && item.like) {
					actions.push(...moreCustomersResults(csSpecs, item));

				} else if (item.category === S.Customer && 'value' in item) {
					actions.push(AppStoreActions.Navigate({ to: [Routes.getSuperCustomerDetailFullPath(item.value)] }));
				}

				actions.forEach(a => fatStore.dispatch(a));
			},
		};
	})
);
