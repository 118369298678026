import { IUserPrefsState, FeatureName as UserPrefsFeatureName } from '@aston/user-prefs';

import { AppStoreState } from './app-store';
import { AccountingStoreState } from './accounting-store';
import { SuperCustomersStoreState } from './super-customers-store';
import { SuperCustomerPageStoreState } from './super-customer-page-store';
import { NotificationsStoreState } from './notifications-store';
import { initialState as appInitialState } from './app-store/state';
import { HomeActionsStoreState } from './home-store';
import { WorkflowSchedulingStoreState } from './workflow-scheduling-store';
import { AdminStoreState } from './admin-store';

export interface RootState {
	app: AppStoreState.IAppState;
	accounting: AccountingStoreState.IAccountingState;
	superCustomers: SuperCustomersStoreState.ISuperCustomersState;
	superCustomerPage: SuperCustomerPageStoreState.ISuperCustomerPageState;
	notifications: NotificationsStoreState.INotificationsState;
	[UserPrefsFeatureName]: IUserPrefsState;
	home: HomeActionsStoreState.IHomeActionsState;
	workflowScheduling: WorkflowSchedulingStoreState.IWorkflowSchedulingState;
	admin: AdminStoreState.IAdminState;
}

export function getStateFromPreviousSession(state: RootState): Partial<RootState> {
	return {
		app: {
			...appInitialState,

			// only put here anonymous store data that
			// should be kept between sessions
			config: state.app.config,
			factorConfig: state.app.factorConfig,
		},
	};
}
