import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { astonCacheId, mergeRecursive } from '@aston/foundation';
import { HttpClient } from '@microsoft/signalr';
import { TranslateLoader } from '@ngx-translate/core';
import { map, mergeMap, forkJoin } from 'rxjs';

import { environment } from '../environments/environment';

import { AppConfiguration } from './app.configuration';

// import the locale, used in currencyPipe for format
registerLocaleData(localeFr);

export function translateLoader(
	http: HttpClient,
	config: AppConfiguration) {

	const filesToConsider = environment[ 'translations' ].filenames.map(f => {
		return { prefix: './assets/i18n/' + f + '.', suffix: '.json' };
	});
	return new MultiTranslateHttpLoader(http, config, filesToConsider);
}

/* custom translation loader, extends the translation file to merge multiple files into a single one */

/* original => https://github.com/ngx-translate/http-loader/issues/44#issuecomment-412510056 */
export class MultiTranslateHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private config: AppConfiguration,
		public resources: { prefix: string, suffix: string }[] = [{ prefix: '/assets/i18n/', suffix: '.json' }]) {
	}

	public getTranslation(lang: string): any {
		return this.config.appConfigLoaded$.pipe(
			map(_ => {
				const resourcesNames = this.resources
				.sort((a, b) => a.prefix.indexOf('v2') < b.prefix.indexOf('v2') ? -1 : 1);

				return resourcesNames.map(resourceName => this.http.get(`${resourceName.prefix}${lang}${resourceName.suffix}${astonCacheId()}`));
			}),
			mergeMap(calls => forkJoin(calls)),
			map(responses => responses.reduce((a, b) => mergeRecursive(a, b))));
	}
}
