
import * as AppStoreActions from 'apps/federation/src/app/root-store/app-store/actions';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserPrefsAppConnector, UserPrefsStoreModule, pageSizesMetaReducer } from '@aston/user-prefs';

import { UserService } from '../user-module/services';

import { AppStoreModule, AppStoreSelectors } from './app-store';
import { AccountingStoreModule } from './accounting-store';
import { SuperDebtorsStoreModule } from './super-customers-store';
import { SuperCustomerPageStoreModule } from './super-customer-page-store';
import { NotificationsStoreModule } from './notifications-store';
import { getStateFromPreviousSession } from './root-state';
import { HomeActionsStoreModule } from './home-store';
import { WorkflowSchedulingStoreModule } from './workflow-scheduling-store';
import { AdminStoreModule } from './admin-store';


export function logout(reducer) {
	return function (state, action) {
		return reducer(action.type === AppStoreActions.Logout.type ? getStateFromPreviousSession(state) : state, action);
	};
}

// export function throwOnUndefined(reducer) {
// 	return function (state, action) {
// 		const newState = reducer(state, action);
// 		Object.keys(newState).map(sliceName => {
// 			if (newState[sliceName] === undefined) {
// 				console.error(`Your ${sliceName} reducer returned undefined. Did you forgot to handle the "default" case ?`);
// 			}
// 		});
// 		return newState;
// 	};
// }


// The metareducers option allow to reset the whole internal state on logout
// see https://ngrx.io/guide/store/metareducers
// export const MetareducedStoreModule = StoreModule.forRoot({}, { metaReducers: [logout, throwOnUndefined] });
export const MetareducedStoreModule = StoreModule.forRoot({}, {
	metaReducers: [logout, pageSizesMetaReducer],
	runtimeChecks: {
		// because we are savages
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
	},
});

export const userPrefsAppConnector = (store: Store, us: UserService) => ({
	currentPreferences: store.select(AppStoreSelectors.selectCurrentUserPrefs),
    updatePreferences: prefs => us.savePreferences(prefs),
    updateFilters: filters => us.saveUserFilters(filters)
})

@NgModule({
	declarations: [],
	imports: [
		AccountingStoreModule,
		AdminStoreModule,
		AppStoreModule,
		CommonModule,
		HomeActionsStoreModule,
		MetareducedStoreModule,
		NotificationsStoreModule,
		UserPrefsStoreModule.forRoot({
			app: {
				provide: UserPrefsAppConnector,
				useFactory: userPrefsAppConnector,
				deps: [Store, UserService]
			}
		}),
		SuperCustomerPageStoreModule,
		SuperDebtorsStoreModule,
		WorkflowSchedulingStoreModule,
		EffectsModule.forRoot([]),
	]
})
export class RootStoreModule { }
