const entityPrefix = /^\/[\w-]*\/\d*/;

export function sharePrefix(url1: string, url2: string, pattern: RegExp = entityPrefix) {
    const segment = url1.match(pattern);
    return segment?.length && url2.includes(segment[0]);
}

export function allQueryParams(): any {
	return location.search.slice(1).split('&').reduce((acc, q) => {
		const param = q.split('=');
		return {...acc, [param[0].toLowerCase()]: param[1]};
	}, {});
}
