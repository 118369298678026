<header class="menu-header">
    <div class="menu-icon">
        <button class="menu-toggle" data-cy="ToggleMenu" type="button" (click)="onToggle()">
            <span></span>
            <span></span>
            <span></span>
        </button>
    </div>
    <div class="menu-logo">
        <a [routerLink]="routes.homePage" routerLinkActive="active">
            <img src="/assets/images/logos/logo_aston_cash_collection.svg" />
        </a>
    </div>
</header>
<ul #menulinks class="menu-links">
    <li>
        <a [routerLink]="routes.homePage" routerLinkActive="active">
            <i class="menu-icon fac fa-custom-home"></i>
            <span>{{ 'Menu.HomePage.Title' | translate }}</span>
        </a>
        <ul class="submenu-links">
            <li>
                <a [routerLink]="routes.homePage" routerLinkActive="active" translate="Menu.HomePage.Item1"></a>
            </li>
            <li>
                <a [routerLink]="routes.dashboardPage" routerLinkActive="active" >{{ 'Menu.HomePage.Item2' | translate }}</a>
            </li>
        </ul>
    </li>
    <li>
        <a [routerLink]="routes.superCustomersList" routerLinkActive="active">
            <i class="menu-icon fac fa-custom-portfolio"></i>
            <span>{{ 'Menu.SuperDebtors' | translate }}</span>
        </a>
    </li>
    <li>
        <a [routerLink]="routes.accountingList" routerLinkActive="active">
            <i class="menu-icon fac fa-custom-paperclip"></i>
            <span>{{ 'Menu.Accounting.Title' | translate }}</span>
        </a>
    </li>
    <li>
        <a [routerLink]="routes.adminToolsIndicatorsPage" routerLinkActive="active">
            <i class="menu-icon far fa-cog"></i>
            <span>{{ 'Menu.AdminTools.Title' | translate }}</span>
        </a>
        <ul class="submenu-links">
            <li>
                <a [routerLink]="routes.adminToolsIndicatorsPage" routerLinkActive="active" >{{ 'Menu.AdminTools.Item8' | translate }}</a>
            </li>
            <li>
                <a [routerLink]="routes.adminToolsReportsPage" routerLinkActive="active" >{{ 'Menu.AdminTools.Item9' | translate }}</a>
            </li>
            @if (isSupport) {
                <li>
                    <a [routerLink]="routes.adminToolsWorkflowSchedulingInstancesList" routerLinkActive="active" >{{ 'Menu.AdminTools.Item5' | translate }}</a>
                </li>
            }
        </ul>
    </li>
</ul>
