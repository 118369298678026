import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ActionType, Loader, IAction, LoaderSpinnerComponent } from '@aston/foundation';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { INotificationItem, INotificationsList } from '../../models';


@Component({
	selector: 'notifications-list',
	templateUrl: './notifications-list.component.html',
	standalone: true,
	imports: [AsyncPipe, FormsModule, NgbButtonsModule, TranslateModule, NotificationItemComponent, LoaderSpinnerComponent]
})
export class NotificationsListComponent {
	@Input() notifications: INotificationsList;
	@Input() loader = Loader.empty();
	@Input() count = 0;
	@Input() showUnreadOnly = false;

	@Output() action: EventEmitter<IAction> = new EventEmitter();
	@Output() filterChange: EventEmitter<boolean> = new EventEmitter();

	onFilterChange() {
		this.filterChange.emit(this.showUnreadOnly);
	}

	select(notification: INotificationItem) {
		this.action.emit({ type: ActionType.SELECT, value: notification });
	}
}
